import {v4 as uuidv4} from 'uuid';

export enum ToastMessageType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning'
}

export class ToastMessage {
    public type: ToastMessageType;
    public content: string;
    public id: string;

    constructor(type: ToastMessageType, content: string) {
        this.type = type;
        this.content = content;
        this.id = uuidv4();
    }
}